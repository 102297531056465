.register__pages {
	flex: 1;
	height: 100%;
	position: relative;
	&::before {
		content: " ";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		@include bgc;
		@include bgi("bg_home.png");
		z-index: 1;
	}
	.landing__content {
		position: relative;
		@include px2res(padding, 0, 250, 105, 250);
		display: flex;
		margin: 0 auto;
		@include bgc;
		@include bgi("bg_register.png");
		background-size: px2vw(1920) auto;
		background-position: top px2vw(-101) center;
		z-index: 2;
		@include portrait {
			@include px2res(padding, 0, 150, 105, 150);
		}
		.wrapper {
			position: relative;
			@include px2res(margin, 182, auto, 0);
			@include px2res(width, 1420);
			@include portrait {
				@include px2res(width, 1620);
			}
			h1 {
				@include px2res(font-size, 96);
				@include px2res(line-height, 103);
				font-weight: 900;
				color: #ffffff;
				text-transform: uppercase;
				text-align: left;
				&.gold {
					color: #ffba00;
				}
				span {
					color: #ffffff;
				}
				@include portrait {
					@include px2res(font-size, 124);
					@include px2res(line-height, 130);
				}
			}
			.description {
				position: relative;
				color: #ffffff;
				@include px2res(font-size, 36);
				@include px2res(line-height, 43);
				@include px2res(width, 1200);
				@include px2res(margin-top, 24);
				@include portrait {
					@include px2res(width, 1300);
					@include px2res(font-size, 44);
					@include px2res(line-height, 46);
				}
			}
			.group {
				display: flex;
				@include px2res(margin, 48, -8, 0);
				@include portrait {
					flex-wrap: wrap;
					justify-content: center;
				}
				.btn {
					@include px2res(width, 412);
					@include px2res(height, 106);
					@include bgc;
					@include px2res(margin, 0, 8);
					&.btn-regis {
						@include bgi("btn_daftar_big.png");
					}
					&.btn-join {
						@include bgi("btn_info.png");
					}
					@include portrait {
						@include px2res(margin, 36, 32, 46);
						@include px2res(width, 412 / 0.6);
						@include px2res(height, 106 / 0.6);
					}
				}
				.whatsapp__container {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					@include px2res(height, 106);
					@include portrait {
						@include px2res(height, 106 / 0.5);
					}

					span {
						color: #ffffff;
						@include px2res(font-size, 24);
						@include px2res(line-height, 29);
						@include px2res(padding-left, 10);
						@include portrait {
							@include px2res(font-size, 44);
							@include px2res(line-height, 38);
							text-align: center;
						}
					}
					.btn-wa {
						@include bgc;
						@include px2res(margin, 0);
						@include px2res(width, 290);
						@include px2res(height, 66);
						@include bgi("btn_info_whastapp.png");
						@include portrait {
							@include px2res(margin, 0);
							@include px2res(width, 290 / 0.5);
							@include px2res(height, 66 /0.5);
						}
					}
				}
			}
		}
	}
}

.support__content__white {
	@include px2res(margin, -34, auto, 0);
	@include px2res(padding, 0, 250);
	position: relative;
	&::before {
		position: absolute;
		content: " ";
		width: 100vw;
		height: 100%;
		top: 0;
		left: 0;
		background: #ffffff;
		z-index: 2;
	}
	.support__list {
		position: relative;
		z-index: 3;
		display: flex;
		@include px2res(width, 1420);
		@include px2res(margin, 36, auto, 0);
		@include px2res(padding, 59, 0, 62.25);
		@include portrait {
			flex-wrap: wrap;
			justify-content: center;
		}
		.support__card {
			@include px2res(width, 345);
			@include px2res(height, 266);
			@include bgc;
			@include px2res(margin, 0, 12);
			@include portrait {
				@include px2res(width, (762 / 1.2));
				@include px2res(height, (587 / 1.2));
				@include px2res(margin, 24);
			}
			&.exposure {
				@include bgi("img_exposure.png");
			}
			&.ingame {
				@include bgi("img_ingame_item.png");
			}
			&.merchandise {
				@include bgi("img_merchandise.png");
			}
			&.network {
				@include bgi("img_network.png");
			}
		}
	}
}
