@font-face {
	font-family: 'GFF';
	font-weight: 400;
	font-display: swap;
	src: url('https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/turnamen/fonts/GFF-Latin-Regular.otf');
}

@font-face {
	font-family: 'GFF';
	font-weight: 200;
	font-display: swap;
	src: url('https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/turnamen/fonts/GFF-Latin-Light.otf');
}

@font-face {
	font-family: 'GFF';
	font-weight: 100;
	font-display: swap;
	src: url('https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/turnamen/fonts/GFF-Latin-Thin.otf');
}

@font-face {
	font-family: 'GFF';
	font-weight: 500;
	font-display: swap;
	src: url('https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/turnamen/fonts/GFF-Latin-Medium.otf');
}

@font-face {
	font-family: 'GFF';
	font-weight: 600;
	font-display: swap;
	src: url('https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/turnamen/fonts/GFF-Latin-Bold.otf');
}

@font-face {
	font-family: 'GFF';
	font-weight: 700;
	font-display: swap;
	src: url('https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/turnamen/fonts/GFF-Latin-ExtraBold.otf');
}

@font-face {
	font-family: 'GFF';
	font-weight: 900;
	font-display: swap;
	src: url('https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/turnamen/fonts/GFF-Latin-Black.otf');
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
