.leaderboard__pages {
	flex: 1;
	height: 100%;
	position: relative;

	&::before {
		content: " ";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		@include bgc;
		// @include bgi("bg_lb.png");
		@include bgi("bg_home.png");
		background-size: cover;
		background-position: top;
		z-index: 1;
	}

	.leaderboard__content {
		position: relative;
		z-index: 3;
		@include px2res(padding, 0, 250, 105, 250);

		@include portrait {
			@include px2res(padding, 0, 150, 105, 150);
		}

		.title {
			width: 100%;
			@include flexcc;
			text-transform: uppercase;
			@include px2res(padding-top, 100);

			&-main {
				font-weight: 900;
				@include px2res(font-size, 150);
				color: #ffffff;

				> span {
					color: #ffba00;
				}

				@include portrait {
					@include px2res(font-size, 170);
				}
			}

			&-sub {
				font-weight: 500;
				@include px2res(font-size, 32);
				color: #ffffff;
				@include px2res(margin-top, 20);

				@include portrait {
					@include px2res(font-size, 44);
				}
			}
		}

		.tab-wrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.tabs {
				aspect-ratio: 4.7/1;
				width: 32%;
				@include px2res(margin, 100, 0, 100, 0);
				// @include px2res(width, 456);
				// @include px2res(height, 97);
				@include bgc;
				cursor: pointer;

				&-Regional {
					@include bgi("reg_inactive.png");
					&.active {
						@include bgi("reg_active.png");
					}
				}
				&-Guild {
					@include bgi("guild_inactive.png");
					&.active {
						@include bgi("guild_active.png");
					}
				}
				&-Sekolah {
					@include bgi("sek_inactive.png");
					&.active {
						@include bgi("sek_active.png");
					}
				}
			}
		}

		.content-wrapper {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			@include px2res(gap, 25);
			@include px2res(margin-bottom, 400);

			@include portrait {
				grid-template-columns: repeat(2, 1fr);
				@include px2res(gap, 75);
			}

			.community-container {
				aspect-ratio: 1/1;
				background-color: lightgray;
				justify-content: space-between;
				@include flexcc;
				@include px2res(border-radius, 5);
				text-decoration: none;

				.community-logo {
					width: 100%;
					flex-grow: 1;
					@include bgc;
					@include bgi("logo_default.png");
					background-size: auto 90%;
					@include px2res(border-radius, 5, 5, 0, 0);
				}

				.community-name {
					@include flexrc;
					height: 25%;
					width: 100%;
					background-color: #ffffff;
					@include px2res(border-radius, 0, 0, 5, 5);
					@include px2res(font-size, 22);
					padding: 0 2%;
					font-weight: 500;
					align-items: center;
					text-align: center;
					color: #000000;

					@include portrait {
						@include px2res(font-size, 48);
					}
				}
			}
		}
	}
}
