.loading {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	overflow: hidden;
	display: grid;
	place-items: center;

	.wp_tips {
		width: 60%;
		font-size: 2vw;
		color: #ffffff;
		text-align: center;
	}
}
