.listevent__pages {
	flex: 1;
	height: 100%;
	position: relative;
	&::before {
		content: " ";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		@include bgc;
		@include bgi("bg_home.png");
		z-index: 1;
	}
	.event__content {
		position: relative;
		@include px2res(padding, 0, 250, 105, 250);
		display: flex;
		margin: 0 auto;
		@include bgc;
		@include bgi("bg_register.png");
		background-size: px2vw(1920) auto;
		background-position: top px2vw(-101) center;
		z-index: 2;
		@include portrait {
			@include px2res(padding, 0, 150, 105, 150);
		}
		.wrapper {
			position: relative;
			@include px2res(margin, 182, auto, 0);
			@include px2res(width, 1420);
			@include portrait {
				@include px2res(width, 1620);
			}
			h1 {
				@include px2res(font-size, 96);
				@include px2res(line-height, 103);
				font-weight: 900;
				color: #ffffff;
				text-transform: uppercase;
				text-align: left;
				span {
					color: #ffffff;
					&.gold {
						color: #ffba00;
					}
				}
				@include portrait {
					@include px2res(font-size, 124);
					@include px2res(line-height, 130);
				}
			}
			.event__group {
				@include px2res(margin, 124, -8, 0);
				@include portrait {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
				.event__container {
					margin-left: 0.5%;
					margin-bottom: 2.5%;
					width: 99%;
					display: flex;
					@include px2res(height, 350);
					@include portrait {
						margin-bottom: 5.5%;
						@include px2res(width, 780);
						@include px2res(height, 1450);
						display: block;
					}
					.poster {
						width: 35%;
						height: 100%;
						position: relative;
						@include portrait {
							width: 100%;
							height: 45%;
						}
						.poster-img {
							width: 100%;
							height: 100%;
							cursor: zoom-in;
							background-size: cover;
							background-position: center;
							background-repeat: no-repeat;
							@include bgi("btn_daftar_big.png");
						}
						.event-type {
							position: absolute;
							pointer-events: none;
							left: 0;
							bottom: 0;
							color: black;
							font-weight: 700;
							@include px2res(font-size, 24);
							@include px2res(padding, 16, 84, 16, 24);
							@include bgi("bg_tournament_type.png");
							background-size: 100% 100%;
						}
					}
					.info {
						width: 65%;
						height: 100%;
						@include portrait {
							width: 100%;
							height: 55%;
						}
						position: relative;
						outline: 1px solid #ffbb0066;
						.title {
							@include px2res(padding, 34, 32, 40, 32);
							font-size: 1.35vw;
							font-weight: 900;
							color: white;
							border-bottom: 1px solid #ffbb0066;
							@include portrait {
								height: 18%;
								display: flex;
								align-items: center;
								overflow-y: auto;
								@include px2res(padding, 15, 32, 15, 32);
								@include px2res(font-size, 35);
							}
						}
						.detail {
							width: 98%;
							display: flex;
							color: white;
							justify-content: space-between;
							@include px2res(margin, 24, 0, 14, 0);
							@include px2res(padding, 0, 32);
							@include portrait {
								display: block;
							}
							.desc {
								width: 70%;
								@include px2res(font-size, 20);
								@include portrait {
									width: 100%;
									@include px2res(font-size, 30);
								}
								&-value {
									@include px2res(margin, 8, 0);
									@include portrait {
										margin-top: 0;
										margin-bottom: 4%;
									}
									font-weight: 600;
									&-lokasi {
										padding-right: 1%;
										@include px2res(height, 60);
										@include portrait {
											@include px2res(height, 100);
										}
										line-height: 150%;
										@extend .desc-value;
										overflow-x: hidden;
										overflow-y: scroll;
									}
								}
							}
							.penyelenggara {
								color: #ffba00;
								text-decoration: underline solid #ffba00;
							}
						}
						.detail-bottom {
							@extend .detail;
							width: 100%;
							@include portrait {
								margin-top: 0;
							}
							.btn__regist__event {
								background-color: #ffba00;
								position: absolute;
								right: 0;
								bottom: 0;
								color: black;
								font-weight: 700;
								@include px2res(font-size, 36);
								@include px2res(padding, 12, 48);
								@include portrait {
									bottom: -5%;
									width: 100%;
									text-align: center;
									@include px2res(font-size, 40);
									@include px2res(padding, 20, 0);
								}
							}
						}
					}
				}
			}
		}
	}
	a {
		color: white;
		cursor: pointer;
	}
	ul {
		display: flex;
		width: 70%;
		margin-left: 15%;
		gap: 3%;
		justify-content: space-between;
		outline: 1px solid #ffbb0066;
		@include px2res(padding, 12, 16);
		@include px2res(font-size, 34);
		@include px2res(margin-top, 64);
		.disabled {
			a {
				color: gray;
			}
		}
		li {
			list-style: none;
		}
	}
	::-webkit-scrollbar {
		@include px2res(width, 8);
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		background: #ffba00;
		border-radius: 15px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #ffba00;
		border-radius: 15px;
	}
}

