.home__pages {
	@include px2res(padding, 0, 250, 105);
	@include bgc;
	// @include bgi("home_bg.png");
	@include bgi("bg_home.png");
	position: relative;
	flex: 1;
	height: 100%;
	width: 100vw;
	@include portrait {
		background-size: cover;
		@include px2res(padding, 0, 150, 105);
	}
	.content {
		margin: 0 auto;
		@include px2res(width, 1420);
		@include portrait {
			@include px2res(width, 1620);
		}
	}
	.landing__content {
		width: 100%;

		.content__left {
			// width: 60vw;
			// @include px2res(height, 1080 - 105);
			position: relative;
			@include px2res(padding, 200, 0);

			.wrapper {
				// position: absolute;
				// top: 50%;
				// transform: translateY(-50%);
				z-index: 33;
				width: 100%;
				h1 {
					@include px2res(font-size, 96);
					@include px2res(line-height, 103);
					font-weight: bold;
					color: #ffffff;
					text-transform: uppercase;
					text-align: left;
					@include portrait {
						@include px2res(font-size, 130);
					}
					&.gold,
					span {
						color: #ffba00;
					}
				}
				.description {
					position: relative;
					color: #ffffff;
					@include px2res(font-size, 33);
					// @include px2res(padding-left, 40);
					// @include px2res(margin-top, 24);
					text-transform: uppercase;

					@include portrait {
						@include px2res(font-size, 48);
						@include px2res(margin, 15, 0);
					}
					&::before {
						// content: " ";
						position: absolute;
						left: 0;
						top: 0;
						@include bgc;
						@include bgi("img_ornament.png");
						@include px2res(width, 18);
						@include px2res(height, 95);
					}
				}
				.home-point {
					width: 100%;
					@include px2res(margin-top, 50);

					ul {
						display: flex;
						justify-content: space-between;
						list-style-type: none;

						@include portrait {
							flex-direction: column;
						}

						li {
							width: fit-content;
							min-width: 26%;
							color: #ffffff;
							@include px2res(font-size, 28);
							@include bgc;
							@include bgi("dot.png");
							@include px2res(background-size, auto, 58);
							background-position: top left;
							@include px2res(padding-left, 85);

							@include portrait {
								@include px2res(font-size, 48);
								@include px2res(height, 70);
								@include px2res(margin-bottom, 10);
							}
						}
					}
				}
				.group {
					// @include px2res(width, 750);
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					row-gap: 10%;
					justify-content: flex-start;
					align-items: center;
					@include px2res(margin, 48, 0);

					@include portrait {
						// flex-direction: column;
						// transform: scale(2);
						// transform-origin: 0 0;
						// @include px2res(margin-bottom, 200);
						// align-items: flex-start;
						justify-content: space-between;
					}

					.btn {
						aspect-ratio: 4.61/1;
						@include px2res(width, 314);
						@include bgc;
						@include px2res(margin, 0, 8);
						background-size: contain;

						&.btn-regis {
							@include bgi("btn_daftar.png");
						}
						&.btn-list {
							@include bgi("btn_list.png");
						}
						&.btn-join {
							@include bgi("btn_join_discord.png");
							@include px2res(width, 251);
						}
						&.btn-wa {
							aspect-ratio: 3.85/1;
							@include bgi("wa_btn.png");
							@include px2res(width, 208);
						}
						&.btn-yl {
							@include bgi("yl_button.png");
							@include px2res(width, 251);
						}

						@include portrait {
							// margin: 0;

							&.btn-regis,
							&.btn-list {
								width: 49%;
								@include px2res(margin, 30, 0);
							}

							&.btn-join,
							&.btn-wa,
							&.btn-yl {
								width: 32%;
								@include px2res(height, 110);
							}
						}
					}
				}
			}
		}
		// .content__right {
		// 	position: absolute;
		// 	right: 0;
		// 	top: 0;
		// 	@include bgc;
		// 	@include bgi("img_landing.png");
		// 	@include px2res(width, 1129);
		// 	@include px2res(height, 1080 - 105);
		// 	@include px2res(background-size, 1129, 1179);
		// }
	}
	.event__content {
		@include px2res(margin-top, 72);
		width: 100%;
		.title {
			color: #ffba00;
			@include px2res(font-size, 48);
			@include px2res(line-height, 58);
			font-weight: 900;
			@include portrait {
				text-align: center;
			}
		}
		.event__list {
			display: flex;
			@include px2res(margin, 12, -12, 0);
			@include portrait {
				flex-wrap: wrap;
				justify-content: center;
			}
			.event__card {
				@include px2res(width, 460);
				@include px2res(height, 306);
				@include bgc;
				@include px2res(margin, 0, 12);
				@include px2res(background-size, 550, 311);
				@include portrait {
					@include px2res(width, 460 / 0.6);
					@include px2res(height, 306 / 0.6);
					@include px2res(margin, 0);
					background-size: cover;
				}
				&.gathering {
					@include bgi("img_gathering.png");
				}
				&.nobar {
					@include bgi("img_nobar.png");
				}
				&.turnamen {
					@include bgi("img_turnamen.png");
				}
			}
		}
	}
	.support__content {
		@include px2res(margin-top, 72);
		width: 100%;
		.title {
			color: #ffba00;
			@include px2res(font-size, 48);
			@include px2res(line-height, 58);
			font-weight: 900;
			@include portrait {
				text-align: center;
			}
		}
		.support__list {
			display: flex;
			@include px2res(margin, 36, -12, 0);
			@include portrait {
				flex-wrap: wrap;
				justify-content: center;
			}
			.support__card {
				@include px2res(width, 345);
				@include px2res(height, 266);
				@include bgc;
				@include px2res(margin, 0, 12);
				@include portrait {
					@include px2res(width, (762 / 1.2));
					@include px2res(height, (587 / 1.2));
					@include px2res(margin, 24);
				}
				&.exposure {
					@include bgi("img_exposure.png");
				}
				&.ingame {
					@include bgi("img_ingame_item.png");
				}
				&.merchandise {
					@include bgi("img_merchandise.png");
				}
				&.network {
					@include bgi("img_network.png");
				}
			}
		}
	}
}
