@import "variable";
@import "font";
@import "reset";
@import "mixins";
@import "functions";

.inter-font {
	font-family: $inter-font-family;
}

html {
	width: 100%;
	height: 100%;
	font-family: $gff-font-family;
	background: rgba(0, 0, 0, 1);
}

body {
	width: 100%;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: rgba(0, 0, 0, 1);
	overflow: auto;
}

#root {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}

#Main {
	width: 100%;
	height: 100%;
}

.btn {
	padding: 0;
	outline: none;
	border: none;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	&:disabled,
	&.disabled {
		cursor: default;
		filter: grayscale(1) !important;
	}
}

.header {
	position: relative;
	top: 0;
	width: 100vw;
	@include bgc;
	@include bgi("bg_header.png");
	@include px2res(padding, 16, 250); // height :  105
	@include portrait {
		@include px2res(padding, 32, 0); // height :  186
		background-position: top left -3vw;
		background-repeat: repeat-x;
	}

	.header__wrapper {
		position: relative;
		@include px2res(width, 1420);
		margin: 0 auto;
		height: 100%;
		display: flex;
		align-items: center;

		@include portrait {
			// @include px2res(width, 1620);
			width: 100%;
			justify-content: center;
		}
		.campgrounds-logo {
			@include px2res(width, 70);
			@include px2res(height, 73);
			@include portrait {
				@include px2res(width, 70 / 0.6);
				@include px2res(height, 73 / 0.6);
			}
			@include bgc;
			@include bgi("img_campground_logo.png");
			background-size: contain;
		}
		.menu {
			@include px2res(margin-left, 64);
			display: flex;
			align-items: center;

			a {
				display: inline-block;
				@include px2res(font-size, 29);
				@include px2res(line-height, 35);
				color: #ffffff;
				@include px2res(padding, 8, 0);
				@include px2res(margin, 0, 24);
				text-transform: uppercase;
				position: relative;
				@include portrait {
					@include px2res(padding, 24, 0);
					@include px2res(font-size, 42);
					@include px2res(line-height, 48);
				}
				&::before {
					content: " ";
					position: absolute;
					width: 100%;
					height: 0;
					background-color: #df9d2a;
					bottom: 0;
					left: 0;
					transition: height 150ms linear;
				}
				&.active::before {
					@include px2res(height, 4);
				}
			}
		}
	}
}

.footer {
	position: relative;
	bottom: 0;
	width: 100vw;
	@include px2res(height, 369);
	@include bgc;
	@include bgi("bg_footer.png");
	.footer__wrapper {
		position: relative;
		@include px2res(width, 1420);
		height: 100%;
		display: flex;
		margin: 0 auto;
		justify-content: space-between;
		align-items: center;
		@include portrait {
			@include px2res(width, 1620);
		}
		.content__left {
			.ff-logo {
				@include px2res(width, 273);
				@include px2res(height, 34);
				@include bgc;
				@include bgi("img_freefire_logo.png");
				@include portrait {
					@include px2res(width, 273 / 0.6);
					@include px2res(height, 34 / 0.6);
				}
			}
			.copyright {
				@include px2res(margin-top, 48);
				font-weight: 500;
				@include px2res(font-size, 20);
				@include px2res(line-height, 24);
				color: #000000;
				@include portrait {
					@include px2res(font-size, 28);
					@include px2res(line-height, 34);
				}
			}
		}
		.content__right {
			.medsos__list {
				.medsos {
					@include px2res(margin, 12, 0);
					display: flex;
					align-items: center;
					@include portrait {
						@include px2res(margin, 24, 0);
					}
					.ico {
						@include px2res(width, 46);
						@include px2res(height, 46);
						@include bgc;
						&.discord {
							@include bgi("ico_discord.png");
						}
						&.whatsapp {
							@include bgi("ico_whatsapp.png");
						}
						&.instagram {
							@include bgi("ico_instagram.png");
						}
					}
					span {
						@include px2res(margin-left, 16);
						font-weight: 500;
						@include px2res(font-size, 24);
						@include px2res(line-height, 29);
						color: #000000;
						@include portrait {
							@include px2res(font-size, 36);
							@include px2res(line-height, 40);
						}
					}
				}
			}
		}
	}
}
.poster-modal {
	padding: 5%;
	width: 100%;
	margin: auto;
	img {
		width: 100%;
		max-height: 70vh;
		margin-top: 3vh;
		object-fit: contain;
	}
}
#modal-close-icon {
	background-color: white;
	border-radius: 25px;
	padding: 1%;
	transform: scale(0.65);
}
.react-responsive-modal {
	&-modal {
		animation-fill-mode: forwards !important;
		background-color: transparent !important;
	}
	&-container {
		animation-fill-mode: forwards !important;
	}
	&-overlay {
		align-items: center !important;
		animation-fill-mode: forwards !important;
		top: 0 !important;
		width: 100%;
		height: 100%;
	}
}
.content-container {
	@include portrait {
		display: flex;
		flex-direction: column;
		min-height: calc(100% - #{px2vw(555)}); //height-navbar 186
	}
}

@import "pages/home";
@import "pages/informasi";
@import "pages/regis";
@import "pages/listevent";
@import "pages/community";
@import "pages/leaderboard";
