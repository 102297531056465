.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.6);
	text-align: center;
	display: none;
	top: 0;
	left: 0;

	&.active {
		display: block;
	}

	.img {
		position: relative;
		width: 33%;
		top: 50%;
		transform: translateY(-50%);
	}
}
