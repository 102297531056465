.info__pages {
	@include bgc;
	@include bgi("bg_informasi.png");
	@include px2res(padding, 0, 250);
	width: 100vw;
	flex: 1;
	height: 100%;
	position: relative;
	@include px2res(line-height, 40);
	@include portrait {
		@include px2res(padding, 0, 150);
	}
	.content {
		@include px2res(width, 1420);
		margin: 0 auto;
		@include portrait {
			@include px2res(width, 1620);
		}
		.landing__content {
			position: relative;
			display: flex;
			flex-direction: column;
			@include px2res(padding-bottom, 105);

			hr {
				width: 100%;
				border: 1px solid #ffbb0066;
				@include px2res(margin, 141, 0, 51);
			}
			.title-container {
				width: 100%;
				height: 5vh;
				display: flex;
				align-items: center;
				@include px2res(margin, 36, 0);
				@include portrait {
					@include px2res(margin-bottom, 72);
				}
				&-subtitle {
					@extend .title-container;
					width: 100%;
					height: 4vh;
					margin-top: 5%;
				}
				.title-arrow {
					@include bgc;
					@include portrait {
						width: 2.5vh;
						height: 50%;
					}
					width: 5vh;
					height: 100%;
					@include px2res(margin-right, 25);
					@include bgi("img_arrow_info.png");
					&-subtitle {
						@extend .title-arrow;
						@include portrait {
							width: 2vh;
							height: 50%;
						}
						width: 4vh;
						height: 100%;
					}
				}
			}
			.title {
				@include px2res(font-size, 64);
				color: #ffba00;
				font-weight: 700;
				text-transform: uppercase;
				@include portrait {
					@include px2res(font-size, 96);
					@include px2res(line-height, 100);
				}
			}
			.subtitle {
				@extend .title;
				@include px2res(font-size, 52);
				@include portrait {
					@include px2res(font-size, 80);
				}
				&__white {
					color: white;
					@include px2res(font-size, 38);
					font-weight: bold;
					margin-bottom: 1%;
				}
			}
			.info__box {
				width: 100%;
				height: auto;
				color: white;
				margin-bottom: 1%;
				background-color: rgb(16, 15, 25);
				@include px2res(padding, 48, 96);
				.content {
					width: 100%;
					@include px2res(margin-left, 34);
					@include px2res(font-size, 32);
					font-weight: 400;
					// margin-bottom: 5%;
					p, ul {
						@include px2res(font-size, 28);
					}
				}
			}
			.dukungan {
				position: relative;
				.top-ornament {
					right: 0;
					width: 50%;
					top: -3.25vh;
					height: 5vh;
					@include portrait {
						top: -1.5vh;
						height: 2.5vh;
					}
					position: absolute;
					background-size: 100% 100%;
					@include bgi("img_border_top_info.png")
				}
				.info__dukungan {
					gap: 5%;
					display: flex;
					font-weight: bold;
					.info__dukungan__left {
						width: 80%;
					}
					h2, .text__bold {
						color: #ffba00;
					}
					h2 {
						@include px2res(font-size, 40);
						@include px2res(margin-bottom, 36);
					}
					h3 {
						font-weight: 600;
						@include px2res(font-size, 32);
						@include px2res(margin-bottom, 36);
					}
					ul {
						@include px2res(font-size, 26);
						@include px2res(margin-bottom, 36);
						li {
							@include px2res(line-height, 35);
						}
					}
					p {
						font-weight: 600;
						@include px2res(font-size, 26);
					}
				}
				.info__disclaimer {
					width: 70%;
					font-weight: 200;
					@include px2res(margin, 18, 0);
					@include px2res(font-size, 20);
					@include px2res(line-height, 30);
				}
			}
			.pengajuan {
				@include px2res(line-height, 35);
				.info__box {
					.title {
						@include px2res(font-size, 35);
						line-height: normal;
						margin-bottom: 1%;
						margin-top: 1.5%;
					}
					.content__sublist {
						display: flex;
						gap: 3%;
						position: relative;
						div {
							h3 {
								@extend .title;
								color: white;
								margin-bottom: 3%;
							}
							p {
								font-weight: 500;
								@include px2res(font-size, 26);
							}
							.content__left {
								@include px2res(width, 640);
								&, content__right {
									@include px2res(padding-left, 34);
								}
							}
						}
					}
				}
			}
			.rules__and__support {
				::-webkit-scrollbar {
					@include px2res(width, 15);
					@include px2res(height, 15);
				}
				::-webkit-scrollbar-track {
					background: transparent;
				}
				::-webkit-scrollbar-thumb {
					background: #ffba00;
					border-radius: 15px;
				}
				::-webkit-scrollbar-thumb:hover {
					background: #ffba00;
					border-radius: 15px;
				}
				.btn__container {
					width: 100%;
					display: flex;
					justify-content: center;
					margin-top: 10%;
					margin-bottom: 3%;
					gap: 15%;
					.btn {
						color: #ffba00;
						font-weight: bold;
						background-size: 100% 100%;
						@include bgi('bg_btn_info.png');
						@include px2res(padding, 44, 80, 34, 80);
						@include px2res(font-size, 44);
						filter: brightness(0.7);
						&_active {
							@extend .btn;
							filter: none;
						}
					}
				}
				.content {
					width: 100%;
					height: 100%;
					@include px2res(padding, 0, 24);
					margin-left: 0;
					overflow-y: auto;
					h3 {
						font-weight: 700;
						@include px2res(font-size, 40);
						@include px2res(margin-bottom, 35);
						
					}
					strong {
						font-weight: bold
					}
					p {
						@include px2res(margin-bottom, 30);
					}
					tbody, tfoot, thead, tr, th, td {
						margin: 5%;
						padding: 10px;
						margin-top: 20px;
						border: 1px solid rgb(195, 195, 195);
						@include px2res(font-size, 26);
					}
					table {
						@include px2res(margin-bottom, 35);
					}
					th {
						font-weight: bold;
					}
				}
				.rules_support {
					height: 40vw;
				}
				.info__box {
					background-color: black;
					outline: 1px solid #ffba00;
				}
			}
		}
	}
	.content__button {
		display: inline-block;
		@include px2res(font-size, 24);
		@include px2res(margin, 44, 0, 44, 34);
		@include px2res(padding, 16, 32);
		color: rgb(58, 58, 58);
		font-weight: bold;
		background-color: #ffba00;
		@include px2res(border-radius, 15);
	}
	.white {
		background-color: white;
	}
	.column1 {
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.column2 {
		margin-left: 0;
	}
	.vertical {
		display: block;
	}
	.content__bottom {
		font-weight: 500;
		margin-bottom: 2%;
		@include px2res(font-size, 26);
		@include px2res(padding-left, 34);
	}
	ul {
		margin-left: 2vw;
		@include portrait {
			margin-left: 2vh;
		}
		font-weight: 500;
	}
}
