.community__pages {
	flex: 1;
	height: 100%;
	position: relative;

	&::before {
		content: " ";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		@include bgc;
		@include bgi("bg_home.png");
		z-index: 1;
	}

	.landing__content {
		position: relative;
		@include px2res(padding, 0, 250, 105, 250);
		display: flex;
		margin: 0 auto;
		@include bgc;
		@include bgi("bg_register.png");
		background-size: px2vw(1920) auto;
		background-position: top px2vw(-101) center;
		z-index: 2;
		@include portrait {
			@include px2res(padding, 0, 150, 105, 150);
		}

		.wrapper {
			position: relative;
			@include px2res(margin, 182, auto, 0);
			@include px2res(width, 1420);
			@include portrait {
				@include px2res(width, 1620);
			}
			h1 {
				@include px2res(font-size, 90);
				font-weight: 900;
				color: #ffffff;
				text-transform: uppercase;
				text-align: left;
				&.gold {
					color: #ffba00;
				}
				span {
					color: #ffffff;
				}
				@include portrait {
					@include px2res(font-size, 124);
					@include px2res(line-height, 130);
				}
			}
			.description {
				position: relative;
				color: #ffffff;
				@include px2res(font-size, 33);
				// @include px2res(padding-left, 40);
				// @include px2res(margin-top, 24);
				text-transform: uppercase;

				@include portrait {
					@include px2res(font-size, 40);
					@include px2res(line-height, 52);
					@include px2res(width, 1000);
				}
				&::before {
					// content: " ";
					position: absolute;
					left: 0;
					top: 0;
					@include bgc;
					@include bgi("img_ornament.png");
					@include px2res(width, 18);
					@include px2res(height, 95);
				}
			}
		}
	}

	.community__content {
		position: relative;
		z-index: 3;
		@include px2res(padding, 0, 250, 105, 250);

		@include portrait {
			@include px2res(padding, 0, 150, 105, 150);
		}

		> span {
			display: block;
			width: fit-content;
			color: #ffffff;
			@include px2res(font-size, 36);
			position: relative;
			left: 50%;
			transform: translateX(-50%);
		}

		.province-list {
			width: 100%;
			@include px2res(margin-bottom, 40);

			&__input {
				@include px2res(width, 645);
				@include px2res(height, 60.77);
				@include px2res(font-size, 32);
				@include px2res(padding-left, 20);
				@include px2res(border-radius, 4);
				text-transform: uppercase;
				font: inherit;
				font-weight: bold;
				color: #000000;

				optgroup {
					font-size: 10px;
				}
			}
		}

		.card-wrapper {
			display: grid;
			@include px2res(gap, 25);
			grid-template-columns: repeat(4, auto);
			justify-content: flex-start;

			@include portrait {
				grid-template-columns: repeat(3, 1fr);
				@include px2res(gap, 50);
			}

			.card {
				aspect-ratio: 1.7/1;
				@include px2res(width, 330);
				@include bgc;
				// @include bgi("wa_bar.png");
				background-image: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/community/Insta_bar.PNG");
				font-weight: 700;
				@include px2res(font-size, 29);
				display: flex;
				align-items: center;
				@include px2res(padding-left, 40);
				color: #000000;

				@include portrait {
					@include px2res(font-size, 38);
					width: 100%;
				}
			}
		}
	}
}
